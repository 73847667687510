import {
  AlarmClock,
  Calendar,
  Car,
  CashFunds,
  NotificationBubble,
  Clock,
  Design,
  DiscountCode,
  Gift,
  Guests,
  HeartStroke,
  HotelProduct,
  LightningBolt,
  Lock,
  MailingAddress,
  MapMarker,
  Mobile,
  PageManager,
  Pencil,
  Photo,
  Photos,
  Plane,
  Rsvp,
  SaveTheDate,
  Schedule,
  Shipping,
  Shop,
  Shuttle,
  Stationery,
  Tags,
  Tip,
  UniversalRegistry,
  Video,
  WebGlobe
} from '@withjoy/joykit/icons';

export const checklistSubItemsActions = {
  verifyEmail: {
    icon: Rsvp,
    link: '/edit/settings/admins'
  },
  checkWeddingLocation: {
    icon: MapMarker,
    link: '/edit/settings'
  },
  setCeremonyDate: {
    icon: Calendar,
    link: '/edit/settings'
  },
  addCountdown: {
    icon: Clock,
    link: '/edit/welcome'
  },
  checkPasswordAndPrivacy: {
    icon: Lock,
    link: '/edit/settings/website'
  },
  uploadManagePhotos: {
    icon: Photo,
    link: '/edit/photos'
  },
  designWebsite: {
    icon: Design,
    link: '/edit/design'
  },
  avoidRepeatedQuestions: {
    icon: Pencil,
    link: '/edit/faq'
  },
  finalizeSchedule: {
    icon: Schedule,
    link: '/edit/schedule'
  },
  uploadVideo: {
    icon: Video,
    link: '/edit/video'
  },
  setJoyURL: {
    icon: WebGlobe,
    link: '/edit/settings/website'
  },
  setupPrivacyOptions: {
    icon: Lock,
    link: '/edit/settings/website'
  },
  togglePages: {
    icon: PageManager,
    link: '/edit/settings/website'
  },
  invitePartner: {
    icon: HeartStroke,
    link: '' // Opens Event Admins modal
  },
  collectGuestInfo: {
    icon: MailingAddress,
    link: '/edit/contact'
  },
  uploadContacts: {
    icon: Mobile,
    link: 'https://withjoy.com/app'
  },
  designPaperCards: {
    icon: Stationery,
    link: '/edit/cards/?ctgy=invitation'
  },
  designSendDigitalCards: {
    icon: SaveTheDate,
    link: '/edit/ecard/savethedate'
  },
  addGifts: {
    icon: Shop,
    link: '/edit/registry/shop'
  },
  addCashFunds: {
    icon: CashFunds,
    link: '/edit/registry/shop/catalog/cash-funds'
  },
  orderGifts: {
    icon: Gift,
    link: '/edit/registry/manage'
  },
  setPhotoUpdateNote: {
    icon: Photos,
    link: '/registry'
  },
  setShippingAddress: {
    icon: Shipping,
    link: '/edit/registry/manage?addressDialogOpen=true'
  },
  installJoyButton: {
    icon: LightningBolt,
    link: '/edit/registry/get-quick-add-button'
  },
  connectRegistry: {
    icon: UniversalRegistry,
    link: '/edit/registry/manage?transferRegistryDialogOpen=true'
  },
  tryAutoThankYouNotes: {
    icon: Tip,
    link: '/edit/registry/track'
  },
  applyDiscount: {
    icon: DiscountCode,
    link: 'https://withjoy.com/help/en/articles/8328296-20-post-wedding-discount'
  },
  saveOnHertz: {
    icon: Car,
    link: '/edit/travel'
  },
  saveOnHotels: {
    icon: DiscountCode,
    link: '/edit/planning/hotelblock'
  },
  giveGuestsTips: {
    icon: Plane,
    link: '/edit/travel'
  },
  groundTransportation: {
    icon: Shuttle,
    link: 'https://withjoy.com/biz/ground-transportation'
  },
  chatWithConcierge: {
    icon: NotificationBubble,
    link: '' // Opens Intercom
  },
  organizeGuests: {
    icon: Tags,
    link: '/edit/guests?chmln=c-66abec1b5cc3320013185b88'
  },
  finalizeGuestList: {
    icon: Guests,
    link: '/edit/guests'
  },
  setupRSVPQuestions: {
    icon: Rsvp,
    link: '/edit/rsvp'
  },
  useTagsForRSVP: {
    icon: LightningBolt,
    link: 'https://withjoy.com/help/en/articles/8319309-asking-rsvp-questions-to-specific-guests'
  },
  /**
   * Deprecated checklist items. Keep them here for older events.
   */
  finalizeDetails: {
    icon: Pencil,
    link: '/edit/faq'
  },
  editAccommodations: {
    icon: HotelProduct,
    link: '/edit/travel'
  },
  editTravelTips: {
    icon: Plane,
    link: '/edit/travel'
  },
  remindGuests: {
    icon: AlarmClock,
    link: '/edit/travel'
  }
};
