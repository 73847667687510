import React, { useState } from 'react';
import { Box, ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { EventPageVisibility, GuestSiteTargetEnum, useGetDashboardPreviewPagesQuery } from '@graphql/generated';
import { constructPreviewUrl } from '@website-designer/components/WebsitePreview';
import { getTargetOrigin } from '@shared/utils/previewMessageBus';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { useWidthObserver } from '@apps/greetingCard/components/Premium/useWidthObserver';
import { IFRAME_WIDTH, PreviewContainer, styles } from './DashboardWebsitePreview.styles';
import { SketchedJoyLogo } from '@apps/admin/common/SketchedJoyLogo';
import { Design, LockFilled, Moon, OpenInNewWindow, Sun } from '@withjoy/joykit/icons';
import { useRouterHelper, useTranslation } from '@shared/core';
import { animationTransition } from '@shared/utils/animationTransition';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { SettingsRoutes } from '@apps/admin/routes/Settings/Settings.routes';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { pxToRem } from '@withjoy/joykit/theme';
import { routePaths as adminRoutePaths } from '@apps/admin/route.paths';

const { isProduction } = getNodeEnv();

const PageCount = ({ icon, label }: { icon: React.ReactNode; label: string }) => {
  return (
    <Flex paddingX={5} columnGap={2}>
      {icon}
      <TextV2 typographyVariant={'label2'}>{label}</TextV2>
    </Flex>
  );
};

const PageCounter = ({ eventHandle }: { eventHandle: string }) => {
  const { t } = useTranslation('adminDashboardV2');
  const { publicPage, protectedPage, off } = t('websitePreview');
  const router = useRouterHelper();
  const isSmallMobile = useMediaQuery(theme => theme.mediaQueries.down({ breakpointAlias: 'xxs' }));

  const { data, loading } = useGetDashboardPreviewPagesQuery({
    variables: {
      eventHandle
    },
    batchMode: 'fast'
  });

  if (loading) return null;

  const pages = {
    public: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.public).length ?? 0,
    passwordProtected: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.passwordProtected).length ?? 0,
    hidden: data?.eventByName?.pages.filter(page => page.visibility === EventPageVisibility.hidden).length ?? 0
  };

  return (
    <Flex
      color={'mono12'}
      transition={animationTransition('color')}
      _hover={{
        color: 'mono8'
      }}
      paddingY={2}
      alignItems={'center'}
      justifyContent={'space-around'}
      width={'100%'}
      as={'a'}
      href={router.buildPath(SettingsRoutes.website)}
    >
      <PageCount icon={<Sun size={'sm'} />} label={isSmallMobile ? pages.public.toString() : publicPage({ count: pages.public })} />
      <PageCount icon={<LockFilled size={'sm'} />} label={isSmallMobile ? pages.passwordProtected.toString() : protectedPage({ count: pages.passwordProtected })} />
      <PageCount icon={<Moon size={'sm'} />} label={isSmallMobile ? pages.hidden.toString() : off({ count: pages.hidden })} />
    </Flex>
  );
};

export const DashboardWebsitePreview: React.FC<{
  eventHandle: string;
  showCounter?: boolean;
  onDesignClick?: () => void;
  onPreviewClick?: () => void;
  showFrame?: boolean;
  showActionsOnHover?: boolean;
}> = ({ eventHandle, showCounter = true, showFrame = true, showActionsOnHover = true, onDesignClick, onPreviewClick }) => {
  const targetOrigin = isProduction ? '' : getTargetOrigin();
  const previewUrl = constructPreviewUrl(targetOrigin, eventHandle, 'adminGuestSitePreview', GuestSiteTargetEnum.charm);

  const { t2 } = useTranslation('adminDashboardV2');
  const { editDesignCta, viewWebsiteCta } = t2('websitePreview');

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const [containerWidth, containerRef] = useWidthObserver();

  const { buildPath } = useRouterHelper();

  // Calculate the scaling factor based on the container's width
  const scale = containerWidth / IFRAME_WIDTH;

  return (
    <Flex width={'100%'} rowGap={5} flexDirection={'column'} alignItems={'center'}>
      <PreviewContainer role="group" __css={styles.previewContainer(scale)} ref={containerRef}>
        {showActionsOnHover && (
          <Flex
            width={'100%'}
            height={'100%'}
            opacity={0}
            transition={animationTransition('opacity')}
            alignItems={'center'}
            justifyContent={'center'}
            position={'absolute'}
            zIndex={1}
            _groupHover={{
              opacity: 1
            }}
            backgroundColor={'rgba(255,255,255,0.2)'}
            flexDirection={'column'}
            rowGap={5}
            paddingX={pxToRem(62)}
          >
            <ButtonV2
              size="lg"
              shape="rounded"
              intent="neutral"
              fullWidth
              startIcon={<Design />}
              boxShadow={boxShadowValuesV2.xxxl}
              onClick={onDesignClick}
              as={'a'}
              href={buildPath(adminRoutePaths.design.path)}
            >
              {editDesignCta}
            </ButtonV2>
            <ButtonV2
              size="lg"
              shape="rounded"
              intent="neutral"
              variant="outline"
              fullWidth
              startIcon={<OpenInNewWindow />}
              backgroundColor={'white'}
              boxShadow={boxShadowValuesV2.xxxl}
              _hover={{ backgroundColor: 'mono2' }}
              as={'a'}
              href={`/${eventHandle}`}
              target={'_blank'}
              onClick={onPreviewClick}
            >
              {viewWebsiteCta}
            </ButtonV2>
          </Flex>
        )}
        <Box __css={styles.browserWindow(scale, showFrame)}>
          <iframe
            // Setting key will guarantee a re-render when the url changes
            key={previewUrl}
            title={previewUrl}
            style={styles.iframe(showFrame)}
            sandbox={`allow-scripts allow-same-origin`}
            src={previewUrl}
            tabIndex={-1}
            onLoad={() => setIframeLoaded(true)}
          />
          <SketchedJoyLogo containerHeight="100%" shouldAnimate={!iframeLoaded} />
        </Box>
      </PreviewContainer>
      {showCounter && <PageCounter eventHandle={eventHandle} />}
    </Flex>
  );
};
