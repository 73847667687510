import { EventPageType, EventPageVisibility, ModelReorderPayload } from '@graphql/generated';
import { createTelemetry, createTelemetryObject } from '@shared/core';

export const category = 'adminSettings';
const pagePrefix = 'admin';
const action = 'SettingsButtonInteracted';
const websiteSettingsAction = 'AdminWebsiteSettingsUpdated';

const actionProps = {
  action,
  category,
  pagePrefix
};

const visibilityMapper = {
  [EventPageVisibility.public]: 'Public',
  [EventPageVisibility.passwordProtected]: 'PasswordProtected',
  [EventPageVisibility.hidden]: 'Hidden'
};

const telemObject = createTelemetryObject({
  pages: {
    website: () => ({
      category,
      pagePrefix,
      page: 'website'
    }),
    pageSettingsDialog: (pageId: string) => ({
      category,
      pagePrefix,
      page: 'page_settings_dialog',
      extraPageInfo: {
        pageId
      }
    }),
    admins: () => ({
      category,
      pagePrefix,
      page: 'admins'
    }),
    general: () => ({
      category,
      pagePrefix,
      page: 'general'
    })
  },
  actions: {
    navigationMenu: (menuItem: string, callback: () => void) => ({
      ...actionProps,
      page: 'website',
      extraInfo: {
        name: 'verticalNavigationMenuClick',
        menuItem: menuItem.toLowerCase()
      },
      callback
    }),
    updatePageOrder: (eventId: string, pageSlug: string, payload: ModelReorderPayload) => ({
      action: 'UpdateMenuSettings',
      category,
      pagePrefix,
      page: 'website',
      extraInfo: {
        name: 'updatePageOrder',
        eventId,
        pageSlug,
        reorderDetails: payload
      }
    }),
    updateSecurityAndPrviacySettings: (eventId: string, itemName: string) => ({
      action: 'UpdateSecurityAndPrivacySettings',
      category,
      pagePrefix,
      page: 'website',
      extraInfo: {
        name: 'updateSecurityAndPrivacySettings',
        eventId,
        itemName
      }
    }),
    editPassword: (eventId: string) => ({
      action: 'PasswordChanged',
      category,
      pagePrefix,
      page: 'website',
      extraInfo: {
        name: 'passwordChanged',
        eventId
      }
    }),
    protectEventInfo: (page: string, visibility: EventPageVisibility, itemName: string) => ({
      action: 'PageSettingsUpdated',
      category,
      extraInfo: {
        pageId: page,
        visibility: visibilityMapper[visibility],
        updateLocation: 'pageSettings_Security',
        visibilityChanged: true,
        itemName
      }
    }),
    pageSettingsChange: (
      page: string,
      visibility: EventPageVisibility,
      isVisibilityChanged: boolean,
      eventName: string,
      title: string,
      isTitleChanged: boolean,
      eventId: string
    ) => ({
      action: 'PageSettingsUpdated',
      category,
      extraInfo: {
        pageId: page,
        visibility: visibilityMapper[visibility],
        updateLocation: 'pageSettings_Menu',
        visibilityChanged: isVisibilityChanged,
        displayTitle: title,
        displayTitleChanged: isTitleChanged,
        url: eventName,
        urlChanged: false,
        eventId
      }
    }),
    allPageSettingsChange: (visibility: EventPageVisibility, eventId: string) => ({
      action: 'AllPageSettingsUpdated',
      category,
      extraInfo: {
        visibility: visibilityMapper[visibility],
        updateLocation: 'pageSettings_Menu',
        eventId
      }
    }),
    copyEventPassword: (eventId: string) => ({
      action: 'CopyEventPassword',
      category,
      extraInfo: {
        name: 'copyEventPassword',
        eventId
      }
    }),
    announcementBannerToggle: (eventId: string, isVisible: boolean) => ({
      action: 'BannerToggle',
      category,
      extraInfo: {
        name: 'bannerToggle',
        eventId,
        isVisible
      }
    }),
    announcementBannerContetChange: (eventId: string, field: 'title' | 'message', content: string) => ({
      action: websiteSettingsAction,
      category,
      extraInfo: {
        name: 'bannerContentEdit',
        eventId,
        field,
        content
      }
    }),
    announcementBannerPreview: (eventId: string) => ({
      action,
      category,
      extraInfo: {
        name: 'bannerPreviewClicked',
        eventId
      }
    }),
    eventCountdownToggle: (eventId: string, isVisible: boolean) => ({
      action: 'CountdownToggle',
      category,
      extraInfo: {
        name: 'countdownToggle',
        eventId,
        isVisible
      }
    }),
    scheduleEventSelected: (eventId: string, scheduleId: string) => ({
      action: websiteSettingsAction,
      category,
      extraInfo: {
        name: 'scheduleEventSelected',
        eventId,
        scheduleId
      }
    }),
    getTheAppLinkToggle: (eventId: string, isVisible: boolean) => ({
      action: websiteSettingsAction,
      category,
      extraInfo: {
        name: 'getTheAppToggle',
        eventId,
        isVisible
      }
    }),
    appPhotoUpdated: (eventId: string, action: 'Update' | 'Delete', status: 'succeeded' | 'failed') => ({
      action: 'PagePhotoUpdated',
      category,
      extraInfo: {
        name: 'pagePhotoUpdated',
        eventId,
        label: action,
        pageId: 'welcome',
        status
      }
    }),

    // Admin settings

    adminInviteClicked: (eventId: string) => ({
      action: 'AdminInviteClicked',
      category,
      extraInfo: {
        name: 'adminInviteClicked',
        eventId
      }
    }),
    adminInviteSuccess: (eventId: string, email: string, firstName: string, lastName: string) => ({
      action: 'AdminInvited',
      category,
      extraInfo: {
        name: 'adminInvited',
        eventId,
        email,
        firstName,
        lastName
      }
    }),
    adminInviteFailed: (eventId: string, email: string, firstName: string, lastName: string) => ({
      action: 'AdminInviteFailed',
      category,
      extraInfo: {
        name: 'adminInviteFailed',
        eventId,
        email,
        firstName,
        lastName
      }
    }),
    adminInviteResendClick: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteRemindClicked',
      category,
      extraInfo: {
        name: 'adminInviteRemindClicked',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteResendSuccess: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteReminded',
      category,
      extraInfo: {
        name: 'adminInviteReminded',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteResendFailed: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteRemindFailed',
      category,
      extraInfo: {
        name: 'adminInviteRemindFailed',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteCancelClicked: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteCancelClicked',
      category,
      extraInfo: {
        name: 'adminInviteCancelClicked',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteCancelSuccess: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteCancelled',
      category,
      extraInfo: {
        name: 'adminInviteCancelled',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteCancelFailed: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminInviteCancelFailed',
      category,
      extraInfo: {
        name: 'adminInviteCancelFailed',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteRevokeClicked: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminRemoveClicked',
      category,
      extraInfo: {
        name: 'adminRemoveClicked',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteRevokeSuccess: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminRemoved',
      category,
      extraInfo: {
        name: 'adminRemoved',
        eventId,
        eventAdminInviteId
      }
    }),
    adminInviteRevokeFailed: (eventId: string, eventAdminInviteId: string) => ({
      action: 'AdminRemoveFailed',
      category,
      extraInfo: {
        name: 'adminRemoveFailed',
        eventId,
        eventAdminInviteId
      }
    }),
    adminTransferOwnershipClicked: (eventId: string, eventAdminInviteId: string) => ({
      action: 'OwnershipTransferClicked',
      category,
      extraInfo: {
        name: 'ownershipTransferClicked',
        eventId,
        eventAdminInviteId
      }
    }),
    adminTransferOwnershipSuccess: (eventId: string, eventAdminInviteId: string) => ({
      action: 'OwnershipTransferSubmit',
      category,
      extraInfo: {
        name: 'ownershipTransferSubmit',
        eventId,
        eventAdminInviteId
      }
    }),
    adminTransferOwnershipFailed: (eventId: string, eventAdminInviteId: string) => ({
      action: 'OwnershipTransferFailed',
      category,
      extraInfo: {
        name: 'ownershipTransferFailed',
        eventId,
        eventAdminInviteId
      }
    }),

    // General settings
    eventURLEditClicked: (eventId: string) => ({
      action: 'HandleEdit',
      category,
      extraInfo: {
        name: 'handleEdit',
        eventId
      }
    }),
    eventURLChanged: (eventId: string, nextValue: string, originalValue: string, persistedValue: string) => ({
      action: 'HandleChanged',
      category,
      extraInfo: {
        name: 'handleChanged',
        eventId,
        nextValue,
        originalValue,
        persistedValue
      }
    }),
    eventURLCopied: (eventId: string, eventURL: string) => ({
      action,
      category,
      extraInfo: {
        name: 'eventURLCopied',
        eventId,
        eventURL
      }
    }),
    customDomainCopied: (eventId: string, customDomain: string) => ({
      action,
      category,
      extraInfo: {
        name: 'customDomainCopied',
        eventId,
        customDomain
      }
    }),
    updateGeneralSettings: (eventId: string, fieldName: string, extraInfo: object = {}) => ({
      action: 'UpdateGeneralSettings',
      category,
      extraInfo: {
        name: 'updateGeneralSettings',
        eventId,
        fieldName,
        ...extraInfo
      }
    }),
    deleteEventDialog: (eventId: string) => ({
      action: 'DeleteWeddingPrompt',
      category,
      extraInfo: {
        name: 'deleteWeddingPrompt',
        eventId
      }
    }),
    addCustomDomainClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'customizeDomainClick',
        eventId
      }
    }),
    editCustomDomainClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'domainEditClick',
        eventId
      }
    }),
    removeCustomDomainClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'domainRemoveClick',
        eventId
      }
    }),
    needHelpClicked: (eventId: string, label: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'needHelpClick',
        eventId,
        label
      }
    }),
    domainRemoveConfirmClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'domainRemoveConfirmClick',
        eventId
      }
    }),
    removeDomainError: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'removeDomainError',
        eventId
      }
    }),
    alreadyHaveDomainClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'alreadyHaveDomainClick',
        eventId
      }
    }),
    openDomainSearchClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'openDomainSearchClick',
        eventId
      }
    }),
    searchAgainClick: (eventId: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'searchAgainClick',
        eventId
      }
    }),
    openIntercomClick: (eventId: string) => ({
      category,
      action: 'CustomDomainSettings',
      extraInfo: {
        name: 'openIntercomClick',
        eventId
      }
    }),
    intercomFailedToDisplay: (eventId: string) => ({
      category,
      action: 'CustomDomainSettings',
      extraInfo: {
        name: 'intercomFailedToDisplay',
        eventId
      }
    }),
    openFAQsClick: (eventId: string) => ({
      category,
      action: 'CustomDomainSettings',
      extraInfo: {
        name: 'openFAQsClick',
        eventId
      }
    }),
    validationError: (eventId: string, domain: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'validationError',
        eventId,
        attemptedAction: 'Save domain',
        errorDescription: 'Not a valid domain',
        domain
      }
    }),
    saveDomainClick: (eventId: string, domain: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'saveDomainClick',
        eventId,
        domain
      }
    }),
    saveDomainError: (eventId: string, domain: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'saveDomainError',
        eventId,
        domain
      }
    }),
    testUrlClick: (eventId: string, domain: string) => ({
      action: 'CustomDomainSettings',
      category,
      extraInfo: {
        name: 'testUrlClick',
        eventId,
        domain
      }
    }),
    navigatedToEditorPage: (eventId: string, pageSlug: string, pageType: EventPageType) => ({
      action,
      category,
      extraInfo: {
        name: 'navigateToEditor',
        eventId,
        pageSlug,
        pageType
      }
    }),
    addCustomPage: (eventId: string) => ({
      action,
      category,
      extraInfo: {
        name: 'addCustomPageButtonClicked',
        eventId
      }
    })
  }
});

const { TelemetryProvider: SettingsTelemetryProvider, useTelemetry: useSettingsTelemetry } = createTelemetry(telemObject);

export { SettingsTelemetryProvider, useSettingsTelemetry };
